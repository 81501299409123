<template>
    <div></div>
    
</template>

<script>
const baseurl='http://39.108.83.106:10008/router';
//const baseurl='http://localhost:10008/router';

export default {
    baseurl


}

</script>

<style>

</style>