import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import { Button, Select} from 'element-ui'

import axios from 'axios'

import global from './components/GlobalComponent';

import VueMeta from 'vue-meta'

Vue.use(VueMeta, {
  keyName: 'metaInfo',
  refreshOnceOnNavigation: true
});

// mavon-editor
import mavonEditor from 'mavon-editor';
import 'mavon-editor/dist/css/index.css';
Vue.use(mavonEditor);

// showdown markdown转html
import showdown from 'showdown';
Vue.prototype.converter = new showdown.Converter();

// 修改页面title
Vue.directive('title', {
  inserted: function (el) {
    document.title = el.dataset.title
  }
})

Vue.config.productionTip = false
Vue.prototype.$http = axios;
Vue.prototype.Global=global;

Vue.use(Button)
Vue.use(Select)

Vue.use(ElementUI)



new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
