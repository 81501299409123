import Vue from 'vue'
import VueRouter from 'vue-router'
// import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/',
  //   name: 'home',
  //   component: () => import('../views/PixiIndex.vue')
  // },
  {
    path: '/',
    name: 'index',
    component: () => import('../views/HomeView1.vue')
  },
  {
    path: '/detail/:id',
    name: 'BlogDetail',
    component: () => import('../views/BlogDetail.vue')
  },
  {
    path: '/impressions',
    name: 'Impressions',
    component: () => import('../views/ImpressionsView.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
